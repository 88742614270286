import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import bannerOne from "../img/homepage/01.png";
import bannerTwo from "../img/homepage/02.png";
import bannerThree from "../img/homepage/03.png";
import Feature01 from "../img/feature-01.png";
import Feature02 from "../img/feature-02.png";
import Feature03 from "../img/feature-03.png";
import Feature04 from "../img/feature-04.png";
import Feature05 from "../img/feature-05.png";
import Feature06 from "../img/feature-06.png";

import Testimonail from "../components/testimonial";
import Blog from "../components/blog";
import scrollTo from "gatsby-plugin-smoothscroll";
import logo from "../img/logo.svg";
import { Link } from "gatsby";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

//Video
import oic_video from "../img/homepage/oic_video.mp4";

export default function Home() {
  const [windowsize, setwindowsize] = useState("");
  useEffect(() => {
    setwindowsize(window);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = windowsize;
    return {
      width,
      height,
    };
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [active, setactive] = useState(false);
  const [glassType, setglassType] = useState("sunglass");
  const [loader, setloader] = useState("none");

  useEffect(() => {
    setTimeout(() => setloader("none"), 1000);

    AOS.init({});
    
  }, []);
  const setActive = () => {
    if (active) {
      setactive(false);
    } else {
      setactive(true);
      scrollTo("#banner");
    }
  };
  const setGlassType = (type) => {
    setglassType(type);
  };
  const GlassType = () => {
    if (glassType === "sunglass") {
      setglassType("round");
    } else {
      setglassType("sunglass");
    }
  };

  const INTERVAL_DELAY = 2000;
  useEffect(() => {
    // document.getElementById("oic_vid").play();
    const interval = setInterval(() => {
      GlassType();
    }, INTERVAL_DELAY);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <Helmet>
        <title>OIC | Digital Eyewear Distribution System </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="OIC |  Digital Eyewear Distribution System "
        />
        <meta
          name="description"
          content="OIC platform designed for Optical Brands, Distributors and retailers to securely drive business and grow sales. One Stop solution for Eyewear Trade"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="OIC platform designed for Optical Brands, Distributors and retailers to securely drive business and grow sales. One Stop solution for Eyewear Trade"
        />
        <meta
          name="facebook-domain-verification"
          content="3w43mkasdg6847ovjo6t6izcl6wgye"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div className={"loader " + loader}>
          <img src={logo} />
        </div>
        <div className={"homeBanner " + glassType} id="banner">
          <div className="container SuperWidth">
            <div className="row">
              <div className="col-md-7 bannerText">
                <h1>
                  TECHNOLOGY FOR YOUR BETTER<br></br>
                  <span>BUSINESS VISION</span>
                </h1>
                <br></br>
                <br></br>
                {/* <a href="#" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>{" "}
                &nbsp;
                <a href="#" className="buttonStyleInvert hvr-forward">
                  Watch Video
  </a> */}
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
        <div className="container SuperWidth padding60">
          <div className="row">
            <div className="col-md-4 aligner" data-aos="fade-right" data-aos-duration="400" data-aos-delay="0">
              <div className="cardBanner">
                <div className="cardTransition"><img src={bannerOne} alt="Women in an optical shop"></img></div>
                <div className="cardBannerText">
                  <h3>WHOLESALER</h3>
                  <p>
                    A distributor is the sole source for retailers to get that
                    product throughout the area.
                  </p>

                  <Link to="/wholesaler" className="buttonOic hvr-forward">
                    Get Started
                  </Link>
                  <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-4 aligner cardSize" data-aos="fade-up" data-aos-duration="400" data-aos-delay="0">
              <div className="cardBanner">
                <div className="cardTransition"><img src={bannerThree} alt="Retailer trying a glass"></img></div>
                <div className="cardBannerText">
                  <h3>RETAILER</h3>
                  <p>
                    A retailer is an entity that sells items to customers for a
                    profit.
                    {/* Retailers can upgrade with OiC premium features to..
                    enhance their sales by POS, CRM and Shophub. */}
                  </p>
                  <Link to="/retailer" className="buttonOic hvr-forward">
                    Get Started
                  </Link>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-4 aligner" data-aos="fade-left" data-aos-duration="400" data-aos-delay="0">
              <div className="cardBanner">
                <div className="cardTransition"><img src={bannerTwo} alt="Optical glass of a Brand"></img></div>
                <div className="cardBannerText">
                  <h3>BRAND</h3>
                  <p>
                    {" "}
                    It's a fact that everyone is incredibly brand aware today.
                    {/* In the case of eyewear, this brand consciousness is... also
                    becoming popular */}
                  </p>
                  <Link to="/brand" className="buttonOic hvr-forward">
                    Explore Now
                  </Link>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60 blueBackground" id="products">
          <div className="container SuperWidth" data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-5">
                <h5>FEATURES</h5>
                <h2>Our Product Features</h2>
                <p>
                  We exclusively designed our products for optical B2B and B2C,
                  to enhance end-toend shopping from brands/distributors,
                  retailers to customers.
                </p>{" "}
                <br></br>
                <Link to="/about-us/" className="buttonStyle hvr-forward">
                  Explore More
                </Link>
                <br></br> <br></br>
              </div>

              <div className="col-md-7">
                <div className="container featureIcons">
                  <Link className="row aligner" to="/market-place/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature01}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>MARKET PLACE</h3>
                      <p>ONLINE PLATFORM TO SELL AND...</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                  <Link className="row aligner" to="/virtual-mirror/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature02}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>VIRTUAL MIRROR</h3>
                      <p>RENOWNED AUGMENTED REALITY...</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                  <Link className="row aligner" to="/pos/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature03}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>POS</h3>
                      <p>INCREASE REVENUE POTENTIAL AND...</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                  <Link className="row aligner" to="/crm/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature04}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>CRM</h3>
                      <p>IS A GAME-CHANGING PIECE OF..</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                  <Link className="row aligner" to="/lens-simulation/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature05}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>LENS SIMULATION</h3>
                      <p>EXPERIENCE LENS SELECTION LIKE..</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                  <Link className="row aligner" to="/website-building/">
                    <div className="col-md-3 col-2 featureIconsimage">
                      <img src={Feature06}></img>
                    </div>
                    <div className="col-md-8 col-8">
                      <h3>WEBSITE BUILDER</h3>
                      <p>EXTEND BUSINESS BEYOND - CREATE...</p>
                    </div>
                    <div className="col-md-1 col-2">
                      <a href="#">
                        <img src="https://oicweb-media.s3.ap-south-1.amazonaws.com/icon-arrow-back.svg"></img>
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div>
          <div className="container SuperWidth">
            <div className="row padding60" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-12">
                <div className="text-center">
                  <h5>ABOUT US</h5>
                  <h2>Why OiC?</h2>
                  <p>
                    The reason for choosing OiC is that we provide B2B and B2C,
                    Our OiC platform allows Brand/Distributor to digitally
                    communicate with each other, but it will not end just there,
                    supporting distributors to connect with consumers and making
                    shopping simple and reliable.
                  </p>
                  <br></br>
                  <Link to="/about-us/" className="buttonStyle hvr-forward">
                    Get Started
                  </Link>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="whyus" data-aos="fade-left" data-aos-duration="400">
            <img src={require("../styles/why-oic.jpg")} alt="OIC logo in a tab" />

            {/* <video id="oic_vid" width="200" height="200">
              <source src={oic_video} type="video/mp4"/>
              Your browser does not support the video tag.
            </video> */}
          </div>
        </div>
        <Blog></Blog>
        <Testimonail />
      </Layout>
    </div>
  );
}
