import { Link } from "gatsby";
import React from "react"
import blog01 from "../img/blog-01.jpg";
import blog02 from "../img/blog-02.jpg";
import blog03 from "../img/blog-03.jpg";
const Blog = () =>{
    return(
        <div className="blueBackground" id="blog">
          <div className="padding60" data-aos="fade-right" data-aos-duration="400">
            <div className="container SuperWidth">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h5>BLOG</h5>
                  <h2>Our Blog</h2>
                  <br></br>
                  <br></br>
                </div>
                <div className="col-md-4 text-center aligner">
                  <div className="blogCard">
                    <div className="cardTransition"><img src={blog01} alt="Eyewear Trend 2021"></img></div>
                    <div className="blogCardText">
                      <h5>EYEWEAR TRENDS IN 2021</h5>
                      <h4>THE BIGGEST EYEWEAR TRENDS OF 2021</h4>
                      <Link to="/blog/the-biggest-eyewear-trends-of-2021/" className="buttonStyle hvr-forward">
                        Read More
                      </Link>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center aligner">
                  <div className="blogCard">
                    <div className="cardTransition"><img src={blog02} alt="Impact of Covid"></img></div>
                    <div className="blogCardText">
                      <h5>IMPACT OF COVID</h5>
                      <h4>
                      IMPACT OF COVID ON EYEWEAR INDUSTRY
                      </h4>
                      <Link to="/blog/impact-of-covid-on-eyewear-industry" className="buttonStyle hvr-forward">
                        Read More
                      </Link>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center aligner">
                  <div className="blogCard">
                    <div className="cardTransition"><img src={blog03} alt="Virtual Mirror Technology"></img></div>
                    <div className="blogCardText">
                      <h5>VIRTUAL MIRROR TECHNOLOGY</h5>
                      <h4>
                        Virtual Mirror Technology - It Will Change The Way You
                        Shop
                      </h4>
                      <Link to="/virtual-mirror/" className="buttonStyle hvr-forward">
                        Learn More
                      </Link>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}
export default Blog